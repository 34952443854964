/* eslint-disable react/prop-types,react/display-name */
import React from 'react';
import { graphql } from 'gatsby';
import { Box, Col, Heading, Row, Text } from '@fast-ai/ui-components';
import { GatsbyImage, getImage } from 'gatsby-theme-fast-ai-sidebar';

import { DefaultLayout } from '../layout';
import { Cards, ScaleIn, Section, Seo } from '../ui';
import { RichFormattedMessage, useTranslateMessage } from '../intl';
import m from '../intl/messages/pageCareers';

const styles = {
	row: {
		flexWrap: 'wrap',
		textAlign: 'left',
	},
};
const CareersPage = ({ data, ...rest }) => {
	const translate = useTranslateMessage();
	const {
		careersHeroImage,
		zoeCoreTeamImage,
		addCareers: { edges: positionsData },
	} = data;

	const positions = positionsData.map(
		({
			node: {
				frontmatter: { title, description },
				fields: { slug },
			},
		}) => ({
			to: `${slug}`,
			heading: title,
			description,
		})
	);
	return (
		<DefaultLayout {...rest}>
			<Seo title={translate(m.title)} />

			<Section variant="stripesPrimary">
				<Row
					sx={{
						...styles.row,
					}}
				>
					<Col span={[12]}>
						<Box
							sx={{
								':hover': {
									'.gatsby-image-wrapper': {
										transform: 'scale(1.4)',
									},
								},
								'.gatsby-image-wrapper': {
									transition: 'transform 10s',
									transform: 'scale(1)',
									position: 'absolute',
									top: 0,
									left: 0,
								},
								display: 'flex',
								width: '100%',
								minWidth: '320px',
								alignItems: 'center',
								flexWrap: 'wrap',
								position: 'relative',
								overflow: 'hidden',
							}}
						>
							<Box
								sx={{
									width: '100%',
									paddingBottom: '43%',
									position: 'relative',
								}}
							>
								<GatsbyImage
									image={getImage(careersHeroImage)}
									alt="Carrers - colleagues while working"
								/>
							</Box>
							<Box
								sx={{
									zIndex: 1,
									width: '100%',
									px: [3, 3, 4],
									py: [0, 0, 0],
									textAlign: ['center', 'left'],
									color: 'contrast',
									position: 'absolute',
									left: 0,
									transform: 'translateY(-50%)',
									top: ['50%', '50%', '66%'],
								}}
							>
								<Heading sx={{ m: [0, 0, 0], p: [0, 0, 0], color: 'contrast' }}>
									<RichFormattedMessage {...m.heading} />
								</Heading>
							</Box>
						</Box>
					</Col>
				</Row>
			</Section>
			<Section variant="stripesSecondary">
				<Row
					sx={{ justifyContent: 'center', alignItems: 'center', ...styles.row }}
				>
					<Col span={[12, 12, 6]}>
						<ScaleIn>
							{({ ref, sx }) => (
								<Box
									ref={ref}
									sx={{
										overflow: 'hidden',
										'.gatsby-image-wrapper': {
											...sx,
										},
									}}
								>
									<GatsbyImage
										image={getImage(zoeCoreTeamImage)}
										alt="Zoe team"
									/>
								</Box>
							)}
						</ScaleIn>
					</Col>
					<Col span={[12, 12, 6]} sx={{ textAlign: 'left' }}>
						<Text variant="subHeading2">
							<RichFormattedMessage {...m.subheading} />
						</Text>
						<Text>
							<RichFormattedMessage {...m.perex} />
						</Text>
					</Col>
				</Row>
			</Section>
			<Section variant="stripes">
				<Row>
					<Col span={12}>
						<Heading>
							<RichFormattedMessage {...m.openPositions} />
						</Heading>
					</Col>
				</Row>
				<Cards items={positions} colProps={{ span: 12 }} clickAble />
			</Section>
		</DefaultLayout>
	);
};

export const pageQuery = graphql`
	query CareersPageQuery {
		careersHeroImage: file(relativePath: { eq: "careers-hero.jpg" }) {
			childImageSharp {
				gatsbyImageData(width: 1200, layout: CONSTRAINED)
			}
		}
		zoeCoreTeamImage: file(relativePath: { eq: "zoe-core-team.jpg" }) {
			childImageSharp {
				gatsbyImageData(width: 1200, layout: CONSTRAINED)
			}
		}
		addCareers: allMdx(filter: { fields: { source: { eq: "careers" } } }) {
			edges {
				node {
					id
					body
					fields {
						slug
					}
					frontmatter {
						title
						description
					}
					parent {
						... on File {
							name
						}
					}
				}
			}
		}
	}
`;
export default CareersPage;
