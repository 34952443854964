import { defineMessages } from 'gatsby-theme-fast-ai-sidebar';

export default defineMessages({
	title: {
		id: 'page.careers.title',
		defaultMessage: 'Careers',
	},
	heading: {
		id: 'page.careers.heading',
		defaultMessage: 'Work with us!{br} We can innovate together.',
	},
	subheading: {
		id: 'page.careers.subheading',
		defaultMessage:
			'Do you want to be part of a team working with cutting‑edge technologies and not get bored at work?',
	},
	perex: {
		id: 'page.careers.perex',
		defaultMessage:
			'You can work with senior colleagues with many years of experience in various implementations and be part of a team that develops an innovative product.',
	},
	openPositions: {
		id: 'page.careers.openPositions',
		defaultMessage: 'Open positions',
	},
});
